/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  title: '',
  // 网站标题
  siteTitle: 'IT学习专区',
  // 网站加载时显示的内容
  siteLoading: '',
  // 网站描述
  siteDescription:
    'IT资讯社是一个专注于it项目分享、前沿技术传播的网站，让科技引领生活，让数字充满想象。',
  // 网站关键字
  siteKeywords:
    'AI、JAVA、C、C+、php、mysql、python、vue、it项目、资讯导航',
  // 白名单配置，不用令牌就能访问
  whileList: ['/', '/login', '/about'],
  // 超级管理员
  superAdmin: 'superAdmin',
  // 所有权限
  allPermission: '*:*:*',
  // 备案号
  recordTitle: '粤ICP备2021092310号-4'
}
